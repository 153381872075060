import React from "react";
import { graphql } from "gatsby";
import CloudinaryImage from "../../components/cloudinaryImage";
import ServiceLayout from "../../components/ServiceLayout";
import ServiceTitle from "../../components/ServiceTitle";
import { sortByDate } from "../../services/util";
import ServiceCard from "../../components/ServiceCard";
import { OfferingTextContent, SectionContainer } from '../../components/offerings';
import "../../styles.css";
import { GatsbyImage } from 'gatsby-plugin-image';

export default props => {
  const websiteImage = props.data.cloudinaryMedia;
  const { title, description, url } = props.data.markdownRemark.frontmatter;

  const faqSections = [{
    header: "The voice of your business",
    subtitle: "",
    detail: "We can edit your existing content or write new articles and content suited to your current needs."
  }, {
    header: "Inform and entertain",
    subtitle: "Make sure your customers get the most out of the written content on your website.",
    detail: "Need a blog post written about your business or an upcoming event? We can write that for you."
  }];

  const moreExamples = (
    <div className="px-8 text-center pt-10 max-w-3xl mx-auto">
      <OfferingTextContent
        header="More examples of our work"
        subtitle=""
        detail=""
      />
    </div>
  );

  const websiteExampleContent = () => {
    const imgComponent = (
      <CloudinaryImage
        imageName={websiteImage.public_id}
        cloudName='frannsoft'
        backgroundColor={true}
        fluid={{ maxWidth: 1300, maxHeight: 240 }}
        alt={title}
        style={{
          borderRadius: `0.25rem 0.25rem 0 0`
        }}
      />
    );

    const serviceCardComponent = (
      <ServiceCard imgComponent={imgComponent} url={url} title={title} description={description} />
    );
    return serviceCardComponent;
  };

  const posts = sortByDate(props.data.allStrapiArticle.edges);
  const titleComponent = (
    <ServiceTitle
      title='Copywriting and Editing'
      subtitle='Need copywriting for your website or marketing material? Need to write a series of articles about your business or and upcoming event? Whether written for you, by you, or a combination of both, your content will always read in the voice of your business. Take a look below to see some of our recent work!'
    />
  );

  return (
    <ServiceLayout titleComponent={titleComponent} wipWiderLayout
      helmetTitle="Copywriting, Blog Editing, Blog Writer, Search Engine Optimization, SEO Cleveland, Westlake, OH | Red Brick Road Studio"
    >
      {posts.map((post, index) => {
        const { subtitle } = post;
        const imgComponent = (
          <img
            className='rounded-t object-cover w-full'
            src={post.featuredImage.url}
          />
        );

        const faqSection = faqSections[index];
        const textContent = faqSection ? (
          <div className="sm:px-12 my-6">
            <OfferingTextContent key={index}
              header={faqSection.header}
              subtitle={faqSection.subtitle}
              detail={faqSection.detail}
            />
          </div>
        ) : null;

        const moreExamplesComponent = index == 1 ? moreExamples : null;

        const serviceCardComponent = (
          <ServiceCard
            key={index}
            imgComponent={imgComponent}
            url={post.fields.slug}
            title={post.title}
            description={subtitle}
          />
        );

        return (
          <div className="md:px-4 lg:px-12 my-12" key={index}>
            <SectionContainer className='hidden sm:flex lg:h-screen-1/4 items-center'>
              {index % 2 ? (
                <>
                  {serviceCardComponent}
                  {textContent}
                </>
              ) : (
                  <>
                    {textContent}
                    {serviceCardComponent}
                  </>
                )}
            </SectionContainer>

            <SectionContainer className='flex sm:hidden'>
              {textContent}
              {serviceCardComponent}
            </SectionContainer>
            {moreExamplesComponent}
          </div>
        );
      })}
      {websiteExampleContent()}
    </ServiceLayout>
  );
};

export const pageQuery = graphql`
  query Copywriting {
    markdownRemark(
      fileAbsolutePath: { regex: "*/(websites)/" }
      frontmatter: { title: { regex: "*/(Montessori)/" } }
    ) {
      fileAbsolutePath
      frontmatter {
        title
        description
        public_id
        url
      }
    }
    cloudinaryMedia(url: { regex: "*/(websites/mcs)/" }) {
      public_id
      url
    }
    allStrapiArticle(filter: { tags: { regex: "/(copywriting)/" } }) {
      edges {
        node {
          id
          title
          fields {
            slug
          }
          subtitle
          featuredImage {
            url
          }
        }
      }
    }
  }
`;
